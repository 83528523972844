import aboutIntro from '../assets/imgs/art_01.jpg';
import aboutImg1 from '../assets/imgs/about/aboutIsa-01.jpg';

import aboutImg3 from '../assets/imgs/about/aboutIsa-03.jpg';
import aboutImg4 from '../assets/imgs/about/aboutIsa-04.jpg';
import aboutImg5 from '../assets/imgs/about/aboutIsa_001.png';

import styles from '../styles/About.module.scss';

export default function About() {
	return (
		<main id="about" className={styles.container}>
			<div className={styles.title}>
				<img src={aboutIntro} alt="Borboleta rosa arte" />
				<h1>Sobre</h1>
			</div>

			<section className={styles.aboutIntro}>
				{/* <p>
					A Nature Terapias nasce com o objetivo de unir
					saberes em prol da saúde integral e do olhar
					holístico para cada pessoa.
				</p> */}

				<h2>
					A Nature Terapias
				</h2>

				<h2>
					nasce com o objetivo de
				</h2>

				<h2>
					unir saberes em prol
				</h2>

				<h2>
					da saúde integral e do
				</h2>

				<h2>
					olhar holístico para
				</h2>

				<h2>
					cada pessoa.
				</h2>
				
			</section>

			<section className={styles.aboutTalk}>
				<img src={aboutImg1} alt="Corpo e Interior" />

				<p>
					Partindo do princípio de que cada ser é um universo diferente dentro de um mesmo mundo, o olhar que vai
					além da doença,
					que busca a causa e a origem dos males que em muitos momentos se manifestam na mente e no corpo.
					Enxergando que esse corpo que reage não está meramente enfermo, mas sim buscando o equilíbrio e a
					consequente cura.
				</p>
			</section>

			<section>
				<div className={styles.aboutDescription}>
					<p>
						Atuamos nas áreas da psicoterapia, das terapias complementares da saúde reconhecidas pela OMS,
						no autocuidado, no autoconhecimento, nas questões que abordam o princípio feminino e masculino (nossos saberes ancestrais),
						e no universo da infância (criança interior).
					</p>

					<p>
						Esses são temas que perpassam pelas pesquisas e interesses dessa que vos fala e escreve com todo o
						coração.
					</p>
				</div>
			</section>

			<section className={styles.descriptionCard}>
				
				<div className={styles.aboutDescriptionTalk}>
					<img src={aboutImg3} alt="Cura Interior" />

					<p>
						Somos nossos principais curadores, a cura está dentro de cada um de nós.
					</p>
				</div>

				<div className={styles.aboutDescriptionTalk}>
					<img  src={aboutImg4} alt="Ouvir seu corpo" />

					<p>
						Escutar seu corpo, suas emoções, é sempre o melhor e o mais primoroso caminho do despertar da consciência.
					</p>
				</div>
			</section>

			<article className={styles.aboutBarnnerContainer}>
				<img className={styles.aboutBannerImg} src={aboutImg5} alt="Mão estendida ao horizonte" />
			</article>
		</main>
	);
};
