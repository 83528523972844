import Isabel from './Isabel';
import About from './About';
import Psicoterapia from './Psicoterapia';
import Contact from './Contact';

// import styles from '../styles/Home.module.css';

// interface HomeProps {
//     children: React.ReactNode;
// }

const Home = () => {
	return (
		<>
			{/* <section id="home" className={styles.containerHeader}>
				<div className={styles.containerHeaderTitle}>
					<h1>Nature Terapias</h1>
					<h3>Autoconhecimento e harmonização Interior</h3>
				</div>
				<div className={styles.space}></div>
			</section> */}

			<Isabel />

			<About />

			<Psicoterapia />

			<Contact />
		</>
	)
}

export default Home;