

import { FaEnvelopeOpenText, FaWhatsapp } from "react-icons/fa";

import styles from "../styles/FloralTherapies.module.scss";

import aboutFlowerTherapyImg1 from '../assets/imgs/floralTherapies/yellowFlowerWithBlueAndOrange.jpg';
import aboutFlowerTherapyImg2 from "../assets/imgs/floralTherapies/isa-03.jpg";

// import anamineseImg1 from "../assets/imgs/floralTherapies/anaminese04.jpg";
// import anamineseImg2 from "../assets/imgs/floralTherapies/anaminese07.jpg";

import floralServiceImg1 from "../assets/imgs/floralTherapies/isa-06.jpg"
import floralServiceImg4 from "../assets/imgs/floralTherapies/floralServiceImg_01.jpg";
import floralServiceImg5 from "../assets/imgs/floralTherapies/floralServiceImg_02.jpg";
import floralServiceImg6 from "../assets/imgs/floralTherapies/floralServiceImg_03.jpg";

import hidergardVonBigenImg1 from "../assets/imgs/floralTherapies/hidergardVonBigen.jpg";
import edwardBachImg1 from "../assets/imgs/floralTherapies/edwardBach.jpg";
import edwardBachImg2 from "../assets/imgs/floralTherapies/bachFlower3.jpg";

import { Link } from "react-router-dom";
import { NTButton } from "../components/Button";

export function FloralTherapies() {
  const url = "https://api.whatsapp.com/send?phone=+5581997185888";

  return (
    <main id="floraltherapies">
      <section id="introFlowerTherapies" className={styles.containerIntro}>
        <h1 className={styles.introTitle} data-text="Terapia Floral">Terapia Floral</h1>
      </section>

      <section id="aboutFlowerTherapy" className={`${styles.containerAboutFlowerTherapy} ${styles.grid}`}>
        {/* <div className={}> */}
        <article>
          <div className={styles.containerText}>
            <h2 className={styles.title}>O que é a Terapia Floral?</h2>

            <p>
              É uma terapia complementar da saúde, que atua no equilíbrio do nosso
              estado emocional, energético e físico. Assim ajudando a complementar
              outros tratamentos.
            </p>
          </div>

          <div className={styles.buttonToDownloadSupplementaryMaterialOnFloralTherapy}>
            <Link to="/downloads/nt_terapia_floral_2022.pdf" target="_blank" download>
              <NTButton className="button backgroundGreen">
                Material Complementar
              </NTButton>
            </Link>
          </div>
        </article>

        <div className={styles.containerAboutFlowerTherapyImage}>
          <img
            src={aboutFlowerTherapyImg1}
            alt="Nessa foto aparece uma linda flor com petalas laranja e azul 
              ao fundo um verde desfocado de suas folas e outras vegetações que 
              compoem a imagem em sua totalidade."
          />
        </div>

        <div className={styles.containerText}>
          <p>
            Ela é reconhecida pela <strong><a href="https://www.who.int/" target="_blank" rel="noopener noreferrer"> OMS </a></strong>
            e presente nas <strong><a href="https://aps.saude.gov.br/ape/pics" target="_blank" rel="noopener noreferrer"> PICS </a></strong>
            (Práticas integrativas da saúde) pelo SUS.
          </p>

          <p>
            É uma terapia natural, extraída de flores silvestres.
            Sua receita é feita de forma individual a partir de
            uma consulta com um Terapeuta Floral habilitado.
          </p>
        </div>

        <div className={styles.containerAboutFlowerTherapyImage}>
          <img
            src={aboutFlowerTherapyImg2}
            alt="Nessa imagem Isabel esta em uma praia sentada a sombra de um 
            coqueiro com o mar ao fundo e com um lindo vestido branco com flores, 
            em suas mãos tem um livro com capa verde."
          />
        </div>
        {/* </div> */}
      </section>

      <section id="floralService" className={styles.containerFloralService}>
        <article className={`${styles.containerText} ${styles.grid}`}>
          <div className={styles.containerFloralServiceTitle}>
            <h2 className={styles.title}>Como é o atendimento Floral?</h2>
          </div>

          <div className={`${styles.containerFloralServiceButton} ${styles.hideOnSmallScreen}`}>
            {/* <div className={styles.contactLinkBox}>
              <h2>Agende um atemdimento com Terapia Floral:</h2>

              <a
                href="mailto:contato@natureterapias.com.br"
              >
                <NTButton className="button backgroundGreen">
                  <FaEnvelopeOpenText />
                  contato@natureterapias.com.br
                </NTButton>
              </a>

              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NTButton className="button backgroundGreen">
                  <FaWhatsapp />
                  Fale Pelo whatsapp
                </NTButton>
              </a>
            </div> */}
          </div>

          <div className={styles.descriptionFloralService}>
            <p>
              Um atendimento em Terapia Floral consiste em uma entrevista (anamnese)
              onde serão apresentadas ao Terapeuta floral as principais causas da
              busca do tratamento.
            </p>

            <p>
              Depois dessa entrevista é avaliado pelo terapeuta a fórmula e
              as essências mais adequadas para cada pessoa, afim de proporcionar o
              principal objetivo: o equilíbrio do campo emocional do paciente.
            </p>

            <p>
              A base de um atendimento floral é compreender as causas e
              origens individuais de cada desequilíbrio emocional, energético ou
              físico.
            </p>

            <p>
              A duração de um atendimento floral varia entre 1h 30min até 2h.
            </p>
          </div>

          <div className={`${styles.containerFloralServiceImage} ${styles.hideOnSmallScreen}`}>
            <img
              src={floralServiceImg1}
              alt="Menina participando de uma atendimento de Terapia Floral via 
              computador, ela esta em sua mesa de estudo de frente para um iMac
              com um lapis fazendo anotações em quanto ouve a terapeuta floral 
              Isabel Santana."
            />
          </div>
        </article>

        <section id="agendamento">
          {/* <div className={styles.containerFloralServiceCard}>
            <div className={styles.containnerAnaminese}>
              <img
                src={anamineseImg1}
                alt="Menina morena muito fofinha com seus lindos cabelos pretos e
                cacheados participando de uma consulta de terapia floral. Ela 
                esta escrevendo em seu bloquinho de anotações enquanto se comunica
                com a terapeuta floral Isabel Santana por meio de um iPad Branco 
                que esta na sua frente em sua mesa de estudos."
              />
            </div>
            <div className={styles.containnerAnaminese}>
              <img
                src={anamineseImg2}
                alt="Nessa foto temos uma menina com blusa laranja e um cachecol 
                na cor vinho e um relógio preto no seu braço direito, ela posui
                cabelos lisos na cor marrom escuro e esta com um lindo sorriso. 
                Ela esta participando de uma atendimento de Terapia Floral via 
                computador, o qual esta na sua frente em uma belissima mesa de 
                estudo. A menina demonstra esta muito atenta e feliz em quanto 
                ouve a terapeuta floral Isabel Santana. Com um lapis em sua mão 
                direita ela faz suas anotações para não esquecer nem uma das 
                orientações da sua terapeuta floral Isabel Santana."
              />
            </div>
          </div> */}

          <div className={styles.containerFloralServiceButton}>
            <div className={styles.backgroundFloralService}></div>
            <div className={styles.contactLinkBox}>
              <h2>Agende um atemdimento com Terapia Floral:</h2>

              <a
                href="mailto:contato@natureterapias.com.br"
              >
                <NTButton className="button backgroundGreen">
                  <FaEnvelopeOpenText />
                  contato@natureterapias.com.br
                </NTButton>
              </a>

              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NTButton className="button backgroundGreen">
                  <FaWhatsapp />
                  Fale Pelo whatsapp
                </NTButton>
              </a>
            </div>
          </div>
          
          <div className={styles.containerFloralServiceCard}>
            <div className={styles.containnerAnaminese}>
              <img
                src={floralServiceImg4}
                alt=""
              />
            </div>
            <div className={styles.containnerAnaminese}>
              <img 
                src={floralServiceImg5} 
                alt="" 
              />
            </div>
            <div className={styles.containnerAnaminese}>
              <img 
                src={floralServiceImg6} 
                alt="" 
              />
            </div>
          </div>
        </section>
      </section>

      <section id="hidergardVonBigen" className={`${styles.containerHowFlowerTherapyCameAbout}  ${styles.grid}`} >
        <div className={`${styles.containerText}`}>
          <article className={styles.descriptionHowFlowerTherapyCameAbout}>
            <h2 className={styles.title}>Como surgiu a terapia floral?</h2>
            <p>
              Com toda certeza, a Terapia com as flores deve ter surgido
              a muito mais tempo do que possamos imaginar, mas os
              primeiros relatos de seu uso, assim como outras medicinas,
              vem do Antigo Egito.
            </p>

            <p>
              Já no século XI a abadessa,
              <strong>
                <a
                  href="https://pt.wikipedia.org/wiki/Hildegarda_de_Bingen"
                  target="_blank"
                  rel="noopener noreferrer"> Monja Beneditina Hildergard Von Bigen </a>
              </strong>
              , era uma estudiosa sobre as plantas medicinais,
              descreveu minuciosamente várias delas e ficou conhecida
              como a precursora da Medicina Holística ou Natural, como
              conhecemos hoje.
            </p>
          </article>
        </div>

        <div className={styles.containerHowFlowerTherapyCameAboutImg}>
          <img src={hidergardVonBigenImg1} alt="" />
        </div>
      </section>

      <section id="edwardBach" className={`${styles.containerEdwardBach} ${styles.grid}`}>
        <div className={styles.containerEdwardBachImg}>
          <img src={edwardBachImg1} alt="" />
        </div>

        <article className={styles.containerText}>
          <h2 className={styles.title}>Dr. Edward Bach</h2>

          <p>
            <strong>
              <a href="https://www.bachcentre.com/pt/" target="_blank" rel="noopener noreferrer"> Edward Bach </a>
            </strong>
            foi um médico bacteriologista, fisiologista e patologista
            que nasceu na Inglaterra (1886-1936).
          </p>

          <p>
            Foi pioneiro em pesquisas sobre vacinas em seu laboratório.
          </p>

          <p>
            Em 1917 atendeu muitos soldados que regressaram na França no período
            da Primeira Guerra Mundial.
          </p>

          <p>
            Ele se sentia insatisfeito pela medicina focar todas as suas atenções
            na doença e não na CAUSA, fato que vivenciamos até os dias de hoje
          </p>
        </article>

        <article className={styles.containerText}>
          <h2 className={styles.title}>Dr. Edward Bach e</h2>
          <h2 className={styles.title}>os Florais de Bach</h2>

          <p>
            Foi então que em 1930 ele deixa seu consultório na cidade e começa a se
            dedicar a pesquisa com as plantas e flores, estudando e catalogando cada
            uma.
          </p>

          <p>
            Assim, desse trabalho onde ele dedicou anos, surgem os 38 florais que
            formam o seu sistema Floral.
          </p>

          <p>
            O Dr.Bach percebeu através de sua intuição, espiritualidade e pesquisas,
            que nossas emoções são um fator muito importante em processos de cura das
            nossas enfermidades, ele percebeu isso na prática em seus atendimentos.
          </p>
        </article>

        <div className={styles.containerEdwardBachImg}>
          <img src={edwardBachImg2} alt="" />
        </div>
      </section>
    </main>
  )
}