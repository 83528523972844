import { useState } from 'react';
import { Link } from 'react-router-dom';

import brand from '../assets/imgs/nt-brand-circle.png';

import styles from './Header.module.scss';

export default function Header() {
  const [miniMenuIsClose, setMiniMenuIsClose] = useState(false);
  // const [miniMenuStatus, setMiniMenuStatus] = useState("closeMenu");

  function openMiniMenuClick() {
    setMiniMenuIsClose(!miniMenuIsClose);
  }

  return (
    <header className="">
      <div className={styles.maxMenu}>
        <Link to="/">Início</Link>
        <Link to="/psychotherapy">Psicoterapia</Link>
        <Link to="/floraltherapies">Terapia Floral</Link>
        {/* <Link to="/about">Sobre</Link> */}
        {/* <Link to="/">Flor da Lua</Link> */}
        {/* <Link to="/">Workshop</Link> */}
        <Link to="/contact">Contato</Link>

      </div>
      
      <Link to="/" className={styles.logoLink}>
        <img src={brand} alt="Logo marca da Nature Terapias" />
      </Link>


      <div className={styles.miniMenu}>
        <input id="menu-hamburguer" type="checkbox" />
        <label htmlFor="menu-hamburguer" onClick={openMiniMenuClick}>
          <div className={styles.menu}>
            <span className={styles.hamburguer}></span>
          </div>
        </label>

        {miniMenuIsClose && (
          <>
            <Link to="/">Inicio</Link>
            <Link to="/psychotherapy">Psicoterapia</Link>
            <Link to="/floraltherapies">Terapia Floral</Link>
            {/* <Link to="/about">Sobre</Link> */}
            {/* <Link to="/">Flor da Lua</Link> */}
            {/* <Link to="/">Workshop</Link> */}
            <Link to="/contact">Contato</Link>
          </>
        )}
      </div>

    </header>
  );
};