import { FaEnvelopeOpenText, FaWhatsapp } from "react-icons/fa";

import contactImg from '../assets/imgs/contact.png';
import { NTButton } from "../components/Button";

import styles from '../styles/Contact.module.scss';

export default function Contact() {
  const url = "https://api.whatsapp.com/send?phone=+5581997185888";

  return (
    <main id="contact">
      <section className={styles.contact}>
        <h1 className={styles.contactTitle}>Contato</h1>

        <div className={styles.contactBlock}>
          <img src={contactImg} alt="Foto de Isabel em quados" />

          <div className={styles.contactLinkBox}>
            <p>Fale com a Nature Terapias:</p>

            <a 
              href="mailto:contato@natureterapias.com.br"
            >
              <NTButton className="button backgroundGreen">
                <FaEnvelopeOpenText />
                contato@natureterapias.com.br
              </NTButton>
            </a>
            
            <a 
              href={url} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <NTButton className="button backgroundGreen">
                <FaWhatsapp />
                Fale Pelo whatsapp
              </NTButton>
            </a>

          </div>
        </div>
      </section>
    </main>
  )
}