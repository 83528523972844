
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaYoutube, FaBlog } from "react-icons/fa";

import styles from './Footer.module.scss';

export default function Footer() {
	const [pageId, setPageId] = useState("home");

	useEffect(() => {
		handleScrollIntoView(pageId)
	}, [pageId]);

	function handleScrollIntoView(id: string) {
		var elmnt = document.getElementById(id);
		if (!elmnt) {
			return "home";
		}
		elmnt.scrollIntoView();
		return elmnt.id;
	}

	return (
		<footer>
			<section className={styles.footerNav}>
				<div className={styles.footerNavgation}>
					<Link to="/" onClick={() => setPageId("home")}>Home</Link>
					<Link to="/isabel" onClick={() => setPageId("isabel")}>Isabel</Link>
					<Link to="/about" onClick={() => setPageId("about")}>Sobre</Link>
					<Link to="/contact" onClick={() => setPageId("contact")}>Contato</Link>
				</div>

				<div className={styles.footerNavgation}>
					<Link to="#event">Eventos</Link>
					<Link to="#florDaLua">Estudos do Feminino</Link>
					<Link to="#workshop">Workshop</Link>
				</div>

				<div className={styles.footerNavgation}>
					<Link to="/psychotherapy" onClick={() => setPageId("psychotherapy")}>Psicoterapia</Link>
					<Link to="/floraltherapies" onClick={() => setPageId("floraltherapies")}>Terapia floral</Link>
				</div>
			</section>

			<section className={styles.footerNav1}>
				<div className={styles.footerSocialMedia}>
					<Link to="#blog"><FaBlog /></Link>

					{/* <a href="https://www.instagram.com/nature.terapias/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a> */}
					<a href="https://www.instagram.com/isabelsantanaterapeuta/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>

					<a href="https://www.youtube.com/channel/UCsiufn0QAv_KYiiwrCnrO5Q" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
				</div>
			</section>

			<section className={styles.copyright}>
				<div>
					<p>&copy; Nature Terapias {new Date().getFullYear()}</p>
				</div>
				<p>Developer by <a href="http://www.bcl-st.com.br" target="_blank" rel="noopener noreferrer">BCL-ST</a></p>
			</section>
		</footer>
	);
};
