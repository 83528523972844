import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Isabel from './pages/Isabel';
import About from   './pages/About';
import Contact from './pages/Contact';
import Psicoterapia from './pages/Psicoterapia';
import { FloralTherapies } from './pages/FloralTherapies';

function Routes() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/isabel" component={Isabel} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/psychotherapy" component={Psicoterapia} />
        <Route path="/floraltherapies" component={FloralTherapies} />
        {/* <Route component={Error} /> */}
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default Routes;