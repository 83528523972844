
import Routes from './routes';

import './styles/globalApp.scss';

function App() {
  return (
    <Routes />
  );
}

export default App;
