import { ButtonHTMLAttributes } from "react"

import "./NTButton.scss";

type NTButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function NTButton(props : NTButtonProps) {
  return (
    <button className="button" {...props} />
  )
}