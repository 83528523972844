import styles from '../styles/Psicoterapia.module.scss';

import isaImg1 from '../assets/imgs/psicoterapia/isabel2.jpg';
import jungImg from '../assets/imgs/psicoterapia/jung.jpg';

export default function Psicoterapia() {
  return (
    <main id="psychotherapy" className={styles.container}>
      <section className={styles.containerDescription}>
        <div className={styles.title}>
          <h1>Psicoterapia</h1>
        </div>

        <div className={styles.containerImage}>
          <img src={isaImg1} alt="Foto - Isabel Santana" />
        </div>
      </section>

      <section className={styles.containerDescription1}>
        <div className={styles.containerDescriptionText}>
          <p>
            Ser psicóloga clínica é adentrar com todo respeito o território sagrado que é apresentado
            a cada cliente que chega, onde sou convidada a cada novo encontro para caminhar junto em
            um processo íntimo e profundo de autoconhecimento.
          </p>

          <p>
            Sou psicóloga de base Analítica/Junguiana, e esse é um dos vários segmentos da psicologia,
            onde, dentre outras questões da nossa existência, aprofundo através dos símbolos, dos nossos
            sonhos, questões transgeracionais (ancestralidade), arquétipos, criança interior, energia
            feminina e masculina, etc.
          </p>

          <p>
            Através de recursos terapêuticos vamos tecendo esse caminhar e aprofundamento terapêutico.
            E assim, com esse breve resumo de digo, seja muito bem vinda(o)!
          </p>
        </div>
      </section>

      <section>
        <article className={styles.containerDescription}>
          <div className={styles.containerDescriptionText}>
            <h2 className={styles.subTitle}>Carl Gustav Jung</h2>

            <p>
              Foi um psiquiatra e psicoterapeuta suíço que fundou a psicologia analítica.
              Jung propôs e desenvolveu os conceitos de personalidade extrovertida e introvertida,
              arquétipo e inconsciente coletivo. Seu trabalho tem sido influente na psiquiatria,
              psicologia, ciência da religião, literatura e áreas afins.
            </p>
          </div>

          <div className={styles.containerImage}>
            <a href="https://junginstitut.ch/" target="_blank" rel="noopener noreferrer">
              <img src={jungImg} alt="Foto de Carl G. Jung - Nature Terapias" />
            </a>
          </div>

        </article>

        <article className={styles.containerDescription1}>
          <div className={styles.containerDescriptionText}>
            <h2 className={styles.subTitle}>Psicologia Analítica</h2>
            <p>
              A <a href="https://pt.wikipedia.org/wiki/Psicologia_anal%C3%ADtica" target="_blank" rel="noopener noreferrer">Psicologia Analítica </a>
              caracteriza-se por sua ampla capacidade de compreensão – diante dos seus conceitos
              – dos fenômenos psíquicos.
            </p>

            <p>
              Para Carl G. Jung, primordialmente, o homem é um todo, ele rejeita a hipótese de uma personalidade
              fragmentada, para ele o homem já nasce um todo, onde no seu processo de desenvolvimento e evolução
              ele deve buscar um grau cada vez mais elevado de coerência e integralidade com essa unidade, um
              processo cada vez mais profundo de consciência e autoconhecimento.
            </p>

            <p>
              A psique é o todo, e essa, por sua vez, se divide em consciente, inconsciente pessoal e inconsciente coletivo.
            </p>
          </div>
        </article>
      </section>
    </main>
  )
}