import imgAboutIsabel from '../assets/imgs/isabel/isabel.jpg';
import imgNT1 from '../assets/imgs/isabel/nt-10.jpg';
import imgNT2 from '../assets/imgs/isabel/nt-10.png';
import imgNT3 from '../assets/imgs/isabel/nt-05.jpg';
import imgNT4 from '../assets/imgs/nt-intro1.jpg';

import styles from '../styles/Isabel.module.scss';

export default function Isabel() {
  return (
    <main id="isabel" className={styles.container}>
      <section className={styles.containerAboutIsabel}>
        <div className={styles.imgAboutIsabel}>
          <img src={imgAboutIsabel} alt="foto de Isabel Santana" />
          <div className={styles.imgBoxBackground}></div>
        </div>

        <div className={styles.textAboutIsabel}>
          <div className={styles.title}>
            <h3>Isabel Santana</h3>
          </div>
          <p>
            Me chamo Isabel, sou psicóloga clínica da abordagem junguiana, atendo adolescentes,
            jovens e adultos.
          </p>
        </div>
      </section>

      <section className={styles.containerAboutDescription}>
        <div className={styles.textDescription}>
          <p>
            Abordo sobre temas como autoconhecimento, despertar da consciência,
            autocuidado, feminino e ancestralidade.
          </p>
        </div>

        <div className={styles.imgAboutDescription}>
          <img src={imgNT1} alt="Foto do espaço Nature Terapias" />
        </div>
      </section>

      <section>
        <div className={styles.textDescription}>
          <p>
            A psicoterapia para mim simboliza dentre tantas outras coisas o nosso reencontro com a
            nossa alma, é o religamento com a nossa comunicação primordial, com o nosso self, com a
            nossa sabedoria interior. Sou terapeuta floral e reikiana, práticas complementares da
            saúde reconhecidas pela OMS, que promovem o bem estar físico, emocional e psíquico.
            Reconheço essas terapias como bálsamos para o nosso dia a dia e alternativas naturais para
            a saúde de forma integral, promovendo a prevenção e complementando outros tratamentos.
          </p>
        </div>

        <div className={styles.textDescription}>
          <p>
            Irei aprofundar mais sobre cada terapia, sobre como vejo e como atuo em cada uma delas.
            Esse espaço é para informar, trocar idéias e crescermos em uma contribuição mútua. Sejam bem vindos!!
          </p>
        </div>

        <div className={styles.imgAboutDescription}>
          <img src={imgNT2} alt="Nature Terapias foto" />
          <div className={styles.imgBoxBackground}></div>
        </div>
      </section>

      <section className={`${styles.containerAboutDescription} ${styles.footerAboutDescription}`}>
        <div className={styles.imgAboutDescription}>
          <img src={imgNT3} alt="Nature Terapias foto" />
        </div>
      
        <div className={styles.imgAboutDescription}>
          <img src={imgNT4} alt="Nature Terapias foto" />
        </div>
      </section>
    </main>
  );
};
